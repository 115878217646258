@import "styles/overidefuse.scss";
* {
    font-family: "Inter var";
    font-weight: 100 900;
    font-display: swap;    
    font-named-instance: "Regular";
}
.min-h-362 {
    min-height: 362px;
}
.min-h-313 {
    min-height: 313px;
}
.ml--4 {
    margin-left: -4px !important;
}
.mt-25p {
    margin-top: 25px;
}
.mt--3 {
    margin-top: -3px !important;
}
.mt-8p {
    margin-top: 8px;
}
.lh-2 {
    line-height: 2 !important;
}
.formio-render {
    .formio-loader {
        border: 6px solid #ffffff;
        border-top: 6px solid #5255e5 !important;
        border-radius: 167%;
    }
    .btn-primary {
        color: #ffffff !important;
        background: #5255e5 !important;
        border: none;
    }
}
.submit_btn_right {
    display: flex !important;
    justify-content: flex-end !important;
}
.alert-success {
    display: none !important;
}
//scroll-bar

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #dee2e6;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #adb5bd;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #adb5bd;
}

.choices__list--multiple .choices__item[data-deletable] {
    background: #4f46e5;
    font-size: 0.9rem;
    font-weight: 800;
    padding: 5px;
}
form .form-input {
    height: 100%;
    width: 100%;
    padding: 0.325rem 0.75rem;
    border-radius: 3px;
    border: 1px solid #ddd;
    color: #172b4d;
    transition: background 0.1s;
    font-size: 15px;
  }
// body.light .mat-select-panel:not([class*="mat-elevation-z"]),
// body .light .mat-select-panel:not([class*="mat-elevation-z"]) {
//     box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
//         0px 4px 5px 0px rgba(0, 0, 0, 0.14),
//         0px 1px 10px 0px rgba(0, 0, 0, 0.12);
//     position: absolute !important;
//     top: -50% !important;
// }

// body.light .mat-select-panel:not([class*="mat-elevation-z"]) {
//     height: 80px;
// }
